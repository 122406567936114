import React from 'react'
import g from 'glamorous'
import Portrait from '../components/Portrait'
import PortraitMobile from '../components/PortraitMobile'
import { Helmet } from 'react-helmet'

import Layout from '../components/Layout'

const AboutPage = () => (
  <Layout>
    <Helmet>
      <title>About | Valerie Pasquiou Interiors & Design</title>
      <meta
        name="description"
        content="Known for her ability to create interiors and spaces with understated sophistication, Valerie Pasquiou is inﬂuenced from her European upbringing and worldwide travels when designing."
      />
    </Helmet>
    <main>
      <Wrapper>
        <Col
          css={{
            paddingTop: `0px`,
            '@media only screen and (max-width : 768px)': {
              display: `none`,
            },
          }}
        >
          <Portrait />
        </Col>
        <Col>
          <H1>About</H1>
          <PortraitMobileWrapper>
            <PortraitMobile />
          </PortraitMobileWrapper>

          <p>
            It is from her origins in Biarritz, France, and her fascination for
            the sea and the beauty of natural environment that Valerie Pasquiou
            developed her love for light, for strong and simple lines. She
            founded her own eponymous practice in L.A in 1997.
          </p>
          <p>
            Valerie Pasquiou worked previously in Los Angeles in the film
            industry as a set designer where she attracted an array of high-
            profile clients, wanting to build elegant interiors with a humble
            attitude. Minimalism / tailoring / timelessness with warmth,
            simplicity with elegance are some of the qualities defining her
            work. From the use of light to the incorporation of noble materials,
            her vision aims to maintain the feeling of walking through an
            exclusive / familiar space.
          </p>

          <p>
            Valerie Pasquiou has worked on numerous projects from luxury brands
            to high end residential homes proposing an artistic and intuitive
            approach which results to a crafted realization.
          </p>
          <p>
            She is keen to create spaces with refined and functional answers to
            deliver design that adds value to the client. The studio, based in
            NY and Paris is locally engaged and internationally connected.
          </p>
          <p>
            VPI work has been featured in domestic and international
            publications including Elle Decor, Architectural Digest, The New
            York Times, Interior Design Magazine, Ideat, AD Collector and was
            awarded several times.
          </p>
        </Col>
      </Wrapper>
    </main>
  </Layout>
)

export default AboutPage

const Wrapper = g.div({
  marginTop: `7em`,
  display: `grid`,
  gridTemplateColumns: `27% 73%`,
  gridGap: `2em`,
  '@media only screen and (max-width : 768px)': {
    display: `block`,
    marginTop: `20px`,
    padding: `0 15px`,
  },
})

const PortraitMobileWrapper = g.div({
  float: `left`,
  marginRight: `12px`,
  marginBottom: `0px`,
  '@media only screen and (min-width : 768px)': {
    display: `none`,
  },
})

const Col = g.div({
  position: `relative`,
  '@media only screen and (max-width : 768px)': {
    marginBottom: `15px`,
  },
})

const H1 = g.h1({
  fontSize: `2.5em`,
  marginTop: `.1em`,
  paddingBottom: `.25em`,
  display: `inline-block`,
  marginBottom: `.4em`,
  display: `none`,
  '@media only screen and (max-width : 768px)': {
    display: `block`,
    fontSize: `2em`,
  },
})
