import React from 'react'
import Img from 'gatsby-image'
import { StaticQuery, graphql } from 'gatsby'
import { css } from 'glamor'

export default () => (
  <StaticQuery
    query={graphql`
      query portraitMobile {
        file(relativePath: { eq: "valerie-pasquiou.jpg" }) {
          childImageSharp {
            fixed(width: 150, height: 226) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={data => (
      <Img
        fixed={data.file.childImageSharp.fixed}
        alt="Portrait of Valérie Pasquiou, interior designers in New York City"
      />
    )}
  />
)
